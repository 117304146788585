* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

@font-face {
  font-display: swap;
  font-family: 'Good Sans';
  src: url('/fonts/GoodSans/GoodSans-Regular.woff');
}

@font-face {
  font-display: swap;
  font-family: 'GoodSans-Light';
  src: url('/fonts/GoodSans/GoodSans-Light.woff');
}

@font-face {
  font-display: swap;
  font-family: 'Reckless';
  src: url('/fonts/Reckless/Reckless-Regular.woff');
}

a {
  color: inherit;
  text-decoration: none;
}
