* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

@font-face {
  font-display: swap;
  font-family: 'Good Sans';
  src: url('/fonts/GoodSans/GoodSans-Regular.woff');
}

@font-face {
  font-display: swap;
  font-family: 'GoodSans-Light';
  src: url('/fonts/GoodSans/GoodSans-Light.woff');
}

@font-face {
  font-display: swap;
  font-family: 'Reckless';
  src: url('/fonts/Reckless/Reckless-Regular.woff');
}

a {
  color: inherit;
  text-decoration: none;
}

@font-face {
font-family: '__GoodSans_6c0529';
src: url(/_next/static/media/a34157e1f7aca69e-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__GoodSans_Fallback_6c0529';src: local("Arial");ascent-override: 86.52%;descent-override: 17.30%;line-gap-override: 0.00%;size-adjust: 115.58%
}.__className_6c0529 {font-family: '__GoodSans_6c0529', '__GoodSans_Fallback_6c0529'
}

